.IconText {
	display: flex;
	align-items: center;
	margin-top: var(--spacing--xs);
	margin-bottom: var(--spacing--xs);
}
.IconText_string {
	color: currentColor;
	font-size: var(--font-size--sm);

	&___spacing-2xs {
		margin-left: var(--spacing--2xs);
	}
	&___spacing-xs {
		margin-left: var(--spacing--xs);
	}
	&___spacing-sm {
		margin-left: var(--spacing--sm);
	}
	&___spacing-md {
		margin-left: var(--spacing--md);
	}
	&___spacing-lg {
		margin-left: var(--spacing--lg);
	}
	&___spacing-xl {
		margin-left: var(--spacing--xl);
	}
	&___spacing-2xl {
		margin-left: var(--spacing--2xl);
	}
}
