@use "sass:math";
@import "../../../../styles/2-tools/tools.rem.scss";
@import "../../../../styles/2-tools/tools.media-query.scss";
@import "../../../../styles/2-tools/tools.color-square.scss";

.TeaserItem {
	display: flex;
	width: 100%;
	height: 100%;

	@include mq("sm", max) {
		flex-direction: column;
	}

	&___Right {
		@include mq("sm") {
			flex-direction: row-reverse;
		}
	}

	&___Top {
		flex-direction: column;
	}

	&___contained {
		height: auto;
	}
}

.TeaserItem_media {
	position: relative;
	width: 50%;

	@media print {
		display: none;
	}

	.TeaserItem___Top & {
		width: 100%;
	}
	.TeaserItem___PDF & {
		height: pxToRem(300);
		width: auto;
		background: var(--color-white);
		padding-top: pxToRem(24);
		padding-bottom: pxToRem(24);
		margin-left: auto;
		margin-right: auto;
		text-align: center;
	}
	&___16-9 {
		aspect-ratio: 16/9;
	}
	&___1-1 {
		aspect-ratio: 1/1;
	}
	&___hideOnMobile {
		@include mq("sm", max) {
			display: none;
		}
	}
	@include mq("sm", max) {
		width: auto;
		max-width: 100vw;
	}
}

.TeaserItem_video {
	display: flex;
	position: static;
	margin-top: auto;
	margin-bottom: auto;
}

.TeaserItem {
	:global(.Picture__override) {
		position: absolute;
		left: 0;
		right: 0;
		top: 0;
	}
}

.TeaserItem_content {
	display: flex;
	flex-flow: column;
	width: 50%;
	flex: 1;
	.TeaserItem___Top & {
		width: 100%;
	}
	padding: var(--spacing--lg);

	@include mq("sm", max) {
		width: auto;
		height: 100%;
	}

	@include color-square(currentColor);

	.TeaserItem___ThemeWhite & {
		@include color-square(var(--color-theme-primary));
	}

	.TeaserItem___top & {
		width: 100%;
		height: 100%;
		min-height: pxToRem(164);
		@include mq("sm", max) {
			width: auto;
			height: auto;
		}
	}
}

.TeaserItem_link {
	display: flex;
	flex-direction: column;
	text-decoration: none;
	color: inherit;
	background-color: inherit;

	&___Left {
		flex: auto;
		flex-direction: row;
		@include mq("sm", max) {
			flex-direction: column;
		}
	}
	&___Right {
		flex: auto;
		flex-direction: row-reverse;
		@include mq("sm", max) {
			flex-direction: column;
		}
	}
}
.TeaserItem_heading___isLink {
	text-decoration: none;
	&:hover,
	&:focus {
		text-decoration: underline;
	}
}
.TeaserItem_text {
	color: currentColor;
	&___noBottomMargin {
		p:last-child {
			margin-bottom: 0;
		}
	}
}

.TeaserItem_contentLink {
	padding-top: var(--spacing--sm);
	margin-top: auto;
}

.TeaserItem_date {
	font-size: var(--font-size--xs);
	color: var(--color-primary-80);
	margin-bottom: var(--spacing--2xs);
}
.TeaserItem_heading {
	margin-bottom: var(--spacing--sm);
}
