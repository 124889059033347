@import "../../../styles/2-tools/tools.list-mixins.scss";
@import "../../../styles/2-tools/tools.media-query.scss";

.SocialLinks {
	@include reset-list;
	display: flex;
	margin-left: auto;
	margin-right: auto;
	@include mq("sm", max) {
		flex-direction: column;
		align-content: flex-start;
		flex-wrap: wrap;
		margin-left: 0;
	}
}

.SocialLinks_item:not(:last-child) {
	margin-right: var(--spacing--2xl);
}

.SocialLinks_link {
	&,
	&:hover,
	&:focus {
		text-decoration: none;
	}
}

.SocialLinks_icon {
	color: var(--color-white);
}
